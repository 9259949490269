@charset "utf-8";
/* CSS Document */

/******************************

[Table of Contents]

1. 1600px
2. 1440px
3. 1280px
4. 1199px
5. 1024px
6. 991px
7. 959px
8. 880px
9. 768px
10. 767px
11. 539px
12. 479px
13. 400px

******************************/

/************
1. 1600px
************/

@media only screen and (max-width: 1600px)
{
	
}

/************
2. 1440px
************/

@media only screen and (max-width: 1440px)
{
	
}

/************
3. 1380px
************/

@media only screen and (max-width: 1380px)
{
	.header
	{
		width: 1200px;
	}
	.header_content
	{
		width: calc(100% - 219px);
	}
	.main_nav_container
	{
		padding-right: 63px;
	}
	.header_side
	{
		width: 219px;
	}
	.header_side span
	{
		font-size: 14px;
	}
	.header_side img
	{
		width: 20px;
		height: 20px;
	}
}

/************
3. 1280px
************/

@media only screen and (max-width: 1280px)
{
	.header
	{
		width: 90%;
	}
	.header_content
	{
		width: 100%;
	}
	.header_side
	{
		display: none !important;
	}
	.main_nav_container
	{
		padding-right: 53px;
	}
	.hero_slide_content h1
	{
		font-size: 56px;
	}
	.card-title
	{
		font-size: 20px;
	}
	.register_content,
	.search_content
	{
		width: 442px;
	}
}

/************
4. 1199px
************/

@media only screen and (max-width: 1199px)
{
	.hero_box
	{
		padding-left: 20px;
	}
}

/************
4. 1100px
************/

@media only screen and (max-width: 1100px)
{
	
}

/************
5. 1024px
************/

@media only screen and (max-width: 1024px)
{
	.main_nav_item
	{
		margin-right: 33px;
	}
}

/************
6. 991px
************/

@media only screen and (max-width: 991px)
{
	.main_nav_container
	{
		display: none;
	}
	.logo_container
	{
		padding-left: 30px;
	}
	.hamburger_container
	{
		display: block;
	}
	.hero_slide_content h1
	{
		font-size: 48px;
	}
	.hero_boxes
	{
		width: 100%;
		height: auto;
		padding-top: 117px;
	}
	.hero_boxes_inner
	{
		position: relative;
		top: auto;
		left: auto;
	}
	.hero_box
	{
		padding-left: 50px;
	}
	.hero_box_col
	{
		margin-bottom: 30px;
	}
	.hero_box_col:last-child
	{
		margin-bottom: 0px;
	}
	.course_box
	{
		margin-bottom: 80px;
	}
	.course_box:nth-last-child(-n+3)
	{
		margin-bottom: 80px;
	}
	.course_box:last-child
	{
		margin-bottom: 0px !important;
	}
	.search_section
	{
		padding-top: 156px;
		padding-bottom: 161px;
	}
	.register_content,
	.search_content
	{
		width: 75%;
	}
	.testimonials_slider_container
	{
		padding-left: 0px;
		padding-right: 0px;
	}
	.event_date
	{
		margin-top: 30px;
	}
	.event_name
	{
		margin-top: 17px;
	}
	.event_content p
	{
		margin-bottom: 0px;
	}
	.newsletter_form_container
	{
		width: 90%;
	}
	.footer_col
	{
		margin-bottom: 30px;
	}
	.footer_col:last-child
	{
		margin-bottom: 0px;
	}
}

/************
7. 959px
************/

@media only screen and (max-width: 959px)
{
	
}

/************
8. 880px
************/

@media only screen and (max-width: 880px)
{
	
}

/************
9. 768px
************/

@media only screen and (max-width: 768px)
{
	
}

/************
10. 767px
************/

@media only screen and (max-width: 767px)
{
	.menu_container
	{
		right: -100vw;
		width: 100vw;
		height: 100vh;
	}
	.newsletter_email
	{
		width: 100%;
	}
	.newsletter_submit_btn
	{
		margin-top: 15px;
	}
}

/************
11. 575px
************/

@media only screen and (max-width: 575px)
{
	h1{font-size: 24px;}
	p{font-size:13px;}
	.header
	{
		height: 74px;
	}
	.logo_container
	{
		padding-left: 15px;
	}
	.logo img
	{
		width: 30px;
	}
	.logo span
	{
		font-size: 16px;
	}
	.hamburger_container
	{
		right: 5px;
	}
	.menu
	{
		top: 70px;
	}
	.menu_item
	{
		margin-bottom: 0px;
	}
	.menu_item a
	{
		font-size: 24px;
	}
	.menu_copyright
	{
		display: none;
	}
	.menu_social_container
	{
		margin-top: 50px;
	}
	.menu_close_container
	{
		right: 30px;
		top: 34px;
	}
	.home-courses
	{
		height: 372px;
	}
	.home_content
	{
		padding-top: 17px;
	    padding-bottom: 11px;
	    padding-left: 22px;
	    padding-right: 24px;
	}
	.home_content h1
	{
		font-size: 30px;
	}
	.card-title
	{
		margin-top: 30px;
	}
	.card-title a
	{
		font-size: 20px;
	}
	.card-text
	{
		font-size: 13px;
	}
	.price_box
	{
		margin-top: 37px;
	}
	.register_section,
	.search_section
	{
		padding-top: 115px;
		padding-bottom: 120px;
	}
	.register_content,
	.search_content
	{
		width: 100%;
		padding-left: 15px;
		padding-right: 15px;
	}
	.register_text
	{
		margin-top: 25px;
	}
	.register_button
	{
		margin-top: 53px;
	}
	.testimonials_slider .owl-dots
	{
		display: none !important;
	}
	.testimonials_item
	{
		padding-bottom: 0px;
	}
	.event_date
	{
		width: 100px;
		height: 100px;
	}
	.event_day
	{
		font-size: 36px;
	}
	.event_month
	{
		font-size: 14px;
	}
	.event_name
	{
		margin-top: 5px;
	}
	.footer_bar
	{
		padding-top: 49px;
		padding-bottom: 52px;
	}
	.footer_social
	{
		margin-top: 20px;
	}
	.footer_copyright span
	{
		font-size: 13px;
	}
}

/************
11. 539px
************/

@media only screen and (max-width: 539px)
{
	
}

/************
12. 480px
************/

@media only screen and (max-width: 480px)
{
	
}

/************
13. 479px
************/

@media only screen and (max-width: 479px)
{
	.header
	{
		height: 60px;
		top: 15px;
	}
	.hero_slide_content h1
	{
		font-size: 28px;
	}
	.hero_slide_content h1 span
	{
		padding-left: 5px;
		padding-right: 5px;
		margin-left: 0px;
		margin-right: 0px;
	}
	.hero_boxes
	{
		padding-top: 80px;
	}
	.hero_box
	{
		padding-left: 15px;
		height: 120px;
	}
	.hero_box_content
	{
		padding-top: 6px;
	}
	.hero_box img
	{
		width: 45px;
	}
	.hero_box_title
	{
		font-size: 18px;
		margin-bottom: 3px;
	}
	.hero_box_link
	{
		font-size: 10px;
	}
}

/************
14. 400px
************/

@media only screen and (max-width: 400px)
{
	
}