#scroll-to-top-btn {
  display: none;
  /*Hidden by default */
  position: fixed;
  /* Fixed/sticky position */
  bottom: 20px;
  /* Place the button at the bottom of the page */
  right: 20px;
  /* Place the button 30px from the right */
  z-index: 99;
  /* Make sure it does not overlap */
  border: none;
  /* Remove borders */
  outline: none;
  /* Remove outline */
  background-color: #FFFFFF;
  /* Set a background color */
  color: white;
  /* Text color */
  cursor: pointer;
  /* Add a mouse pointer on hover */
  padding: 15px;
  /* Some padding */
  font-size: 14px;
  /* Increase font size */
  color: #121212;

  border: 1px solid gray;
}

#scroll-to-top-btn:hover {
  background-color: #ffb606;
  /* Add a dark-grey background on hover */
  color: #fff;
}