.logo span {
  font-size: 28px;
}

.logo span.footer-title {
  font-size: 20px;
}

.logo a img {
  height: 70px;
}

#impressumModal {
  color: rgba(65, 65, 65, 1);
}
