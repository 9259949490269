/* mandatory styles */

@import '~bootstrap';
@import '../plugins/fontawesome-free-5.0.1/css/fontawesome-all.css';
@import '../plugins/OwlCarousel2-2.2.1/owl.carousel.css';
@import '../plugins/OwlCarousel2-2.2.1/owl.theme.default.css';
@import '../plugins/OwlCarousel2-2.2.1/animate.css';
@import './scroll-to-top-button.css';
/* main page styles */

@import './main_styles.css';
@import './responsive.css';
/* courses page styles */

@import './elements_styles.css';
@import './elements_responsive.css';
@import './courses_styles.css';
@import './courses_responsive.css';
/* contact page styles */

@import './contact_styles.css';
@import './contact_responsive.css';

@import './additional-styles.css';
