@charset "utf-8";

/* custom */
.validation-error {
  border: 1px solid #DD0000 !important;
}

#form-not-submited.error {
  color: #DD0000;
}

.contact-form-status {
  cursor: pointer;
}

.mail-send-loader {
  color: #ffb606;
}

#contact_send_btn.disabled {
  background: #6c757d;
}

#google_map {
  height: 330px;
}

/* /custom */
.home_background_container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.home_background {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.home_content {
  position: absolute;
  left: 50%;
  bottom: 109px;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  background: #ffb606;
  padding-top: 24px;
  padding-bottom: 18px;
  padding-left: 39px;
  padding-right: 42px;
}

.home_content h1 {
  font-size: 72px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 0.5;
  white-space: nowrap;
}

.home-contact {
  width: 100%;
  height: 447px;
}

/*********************************
8. Buttons
*********************************/

.button {
  cursor: pointer;
}

.button:hover {
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
}

.button a {
  font-size: 14px;
  line-height: 48px;
  font-weight: 700;
  text-transform: uppercase;
}

.button_1 {
  width: 202px;
  height: 48px;
}

/*********************************
9. Contact
*********************************/

.contact {
  padding-top: 106px;
  padding-bottom: 117px;
}

.contact_title {
  font-size: 36px;
  font-weight: 500;
  color: #1a1a1a;
}

.contact_form_container {
  margin-top: 30px;
}

.input_field {
  width: 100%;
  background: #f8f4f4;
  border: solid 2px transparent;
  margin-bottom: 24px;
  height: 42px;
  padding-left: 33px;
}

.input_field:focus {
  outline: none !important;
  border-color: #ffb606;
}

.text_field {
  width: 100%;
  height: 189px;
  background: #f8f4f4;
  border: solid 2px transparent;
  padding-left: 33px;
  margin-bottom: 24px;
}

.text_field:focus {
  outline: none !important;
  border-color: #ffb606;
}

.contact_send_btn {
  width: 100%;
  height: 48px;
  background: #ffb606;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: #FFFFFF;
  cursor: pointer;
  border: none;
}

.contact_send_btn:focus {
  outline: solid 1px #ffb606;
  border: green !important;
}

/*********************************
10. About
*********************************/

.about_title {
  font-size: 36px;
  font-weight: 500;
  color: #1a1a1a;
}

.about_text {
  margin-top: 20px;
}

.contact_info {
  margin-top: 64px;
  padding-left: 46px;
}

.contact_info_item {
  font-size: 14px;
  font-weight: 400;
  color: rgba(65, 65, 65, 1);
  margin-bottom: 22px;
}

.contact_info_item:last-child {
  margin-bottom: 0px;
}

.contact_info_icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  vertical-align: middle;
  margin-right: 10px;
}

.contact_info_icon img {
  width: 100%;
}

/*********************************
11. Google Map
*********************************/

#google_map {
  width: 100%;
  height: 532px;
  margin-top: 117px;
}

.map_container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#map {
  width: 100%;
  height: 532px;
}
